/*
* @Author: 曹俊杰
* @Date: 2024-09-24 09:27:41
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-24 09:49:17
*/企业管理
<template>
    <div class="enterprise">
        <div class="left">
            <c-menu :list="menuList" />
        </div>
        <div class="right">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
    </div>
</template>
<script>
import cMenu from "@/components/layouts/cMenu.vue"
export default {
    components: {
        cMenu
    },
    data() {
        return {
            menuList: [
                {
                    title: '员工管理',
                    name: 'accountColleague',
                    icon: 'el-icon-ali-fengxianpaicha'
                },
                {
                    title: '印章管理',
                    name: 'sealManagement',
                    icon: 'el-icon-ali-paichajilu'
                }
            ]
        };
    },
}
</script>
<style lang='scss' scoped>
.enterprise {
    display: flex;
    padding-top: 16px;

    .left {
        width: 208px;
        padding-left: 24px;
        padding-right: 16px;
    }

    .right {
        flex: 1;
        padding-right: 20px;
    }
}
</style>